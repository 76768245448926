//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from "vuex";
import eventBus from "@/plugins/eventBus";
import * as _ from "underscore";
import XLSX from 'xlsx'
export default {
	name: "AICHATS",
	data() {
		return {
			jsonPopup: false,
			AISessions: [],
			currentSessions: {},
			jsonData: {},
			sessionIndex: 0,
			showInnerLoading: false,
      showInnerDownloadLoading: false,
			messageLoading: false,
			showError: false,
			showAllIntents: false,
			availableToLoad: true,
			sessionLoading: false,
			sessionChanged: false,
			pageNr: 1,
			pageSize: 10,
			totSize: 0,
			updateMessagesList: []
		};
	},
	computed: {
    ...mapGetters({
				getFilterDate: "getFilterDate",
				currentAIAccount: "getCurrentAIAccount",
				currentUser: "getCurrentUser",
				darkTheme: "getDarkTheme"
			}),
			max() {
				return Math.ceil(this.totSize / this.pageSize);
			}
	},
	methods: {
    ...mapActions({
				requestChannelLogs: "requestChannelLogs",
				toggelLoader: "toggelLoader",
				updateMessageStatus: "updateMessageStatus",
				updateAISession: "updateAISession",
        requestAiMessageLog: "requestAiMessageLog"
			}),
			showJsonpopup(message) {
				const that = this;
				that.jsonData = message;
				that.jsonPopup = true;
			},
			updateChannelLogs(data, index) {
				const that = this;
				that.showInnerLoading = true;
				that.showError = false;
				that.requestChannelLogs(data).then(res => {
					that.totSize = res.totSize;
					that.pageNr += 1;
          _.map(res.orderedArr , session => {
            if(!_.has(session, 'reviewStatus')) {
              session.reviewStatus = 'unchecked'
            }
            return session
          })
					const unique = _.uniq(res.orderedArr, "id");
					that.AISessions = unique;
					if(res.orderedArr.length) {
						if(index) {
							that.updateCurrentSession(res.orderedArr[index], index);
							that.sessionIndex = index;
						} else {
							that.updateCurrentSession(res.orderedArr[0]), 0;
							that.sessionIndex = 0;
						}
					}
					that.showInnerLoading = false;
					that.showError = true;
				}).catch(err => {});
			},
			loadAutoMessages(index, done) {
				const that = this;
				if(that.AISessions.length < that.totSize && !that.showInnerLoading && that.availableToLoad === true) {
					setTimeout(() => {
						that.loadMoreChannelLogs();
						done();
					}, 5000);
				} else {
					done(true);
				}
			},
			loadMoreChannelLogs() {
				const that = this;
				that.messageLoading = true;
				let pageSize;
				const remainingSessions = that.totSize - that.AISessions.length;
				if(remainingSessions > 10) {
					pageSize = 10;
				} else {
					pageSize = remainingSessions;
				}
				const data = {
					aiAccount: that.currentAIAccount.id,
					startdate: that.getFilterDate.start,
					enddate: that.getFilterDate.end,
					pageNr: that.pageNr,
					pageSize,
					firstCall: false // add a static parameter to show active message on frontend
				};
				that.requestChannelLogs(data).then(res => {
					if(res.orderedArr.length > 0) {
						that.totSize = res.totSize;
						that.pageNr += 1;
            _.map(res.orderedArr , session => {
              if(!_.has(session, 'reviewStatus')) {
                session.reviewStatus = 'unchecked'
              }
              return session
            })
						const AISessions = that.AISessions.concat(res.orderedArr);
						const unique = _.uniq(AISessions, "id");
						that.AISessions = unique;
						that.messageLoading = false;
						that.showInnerLoading = false;
						that.availableToLoad = true;
					} else {
						that.availableToLoad = false;
						that.showInnerLoading = false;
						that.showError = true;
					}
				}).catch(err => {});
			},
			updateChannelLogsList(session) {
				const that = this;
				const AISessions = _.map(that.AISessions, csession => {
					if(csession !== undefined) {
						if(csession.messages.length > 0) {
							csession.starred = _.some(csession.messages, message => message.starred);
							csession.flagged = _.some(csession.messages, message => message.reviewStatus == "flagged");
							csession.reviewed = _.some(csession.messages, message => message.reviewStatus == "reviewed");
							const greenLight = _.countBy(csession.userMessages, message => message.reviewStatus == undefined || message.reviewStatus == "unflagged" || message.reviewStatus == "reviewed").true || 0;
							const redLight = _.countBy(csession.userMessages, message => message.reviewStatus == "flagged").true || 0;
							csession.trafficLightInfo = {
								total: csession.userMsg,
								greenLight: {
									count: greenLight,
									percentage: (greenLight / csession.userMsg) * 100
								},
								redLight: {
									count: redLight,
									percentage: (redLight / csession.userMsg) * 100
								}
							};
							if(csession.userMsg > 0) {
								csession.showTrafficInfo = true;
							} else {
								csession.showTrafficInfo = false;
							}
						} else {
							csession.starred = false;
							csession.flagged = false;
							csession.reviewed = false;
							csession.showTrafficInfo = false;
						}
						if(csession.id == session.id) {
							csession.class = "activeChat";
              csession.reviewStatus = session.reviewStatus
							return csession;
						}
						csession.class = "";
						return csession;
					}
				});
				const unique = _.uniq(AISessions, "id");
				that.AISessions = unique;
			},
			updateMessageStar(message) {
				const that = this;
				let updateStarred;
				const {
					starred
				} = message;
				if(starred == 0) {
					updateStarred = 1;
				} else {
					updateStarred = 0;
				}
				message.starred = updateStarred;
				if(that.updateMessagesList.length == 0) {
					that.updateMessagesList.push({
						id: message.id,
						starred: updateStarred
					});
				} else {
					const findwhere = _.where(that.updateMessagesList, {
						id: message.id
					});
					if(findwhere.length > 0) {
						const indexOfDuplicate = that.updateMessagesList.findIndex(x => x.id === message.id);
						that.updateMessagesList[indexOfDuplicate].starred = updateStarred;
					} else {
						that.updateMessagesList.push({
							id: message.id,
							starred: updateStarred
						});
					}
				}
				that.updateChannelLogsList(that.currentSessions);
			},
			updateMessageReview(message) {
				const that = this;
				that.sessionChanged = false;
				let updatereviewStatus;
				const {
					reviewStatus
				} = message;
				if(reviewStatus == "unflagged") {
					updatereviewStatus = "flagged";
				} else if(reviewStatus == "flagged") {
					updatereviewStatus = "reviewed";
				} else {
					updatereviewStatus = "unflagged";
				}
				message.reviewStatus = updatereviewStatus;
				if(that.updateMessagesList.length == 0) {
					that.updateMessagesList.push({
						id: message.id,
						reviewStatus: updatereviewStatus
					});
				} else {
					const findwhere = _.where(that.updateMessagesList, {
						id: message.id
					});
					if(findwhere.length > 0) {
						const indexOfDuplicate = that.updateMessagesList.findIndex(x => x.id === message.id);
						that.updateMessagesList[indexOfDuplicate].reviewStatus = updatereviewStatus;
					} else {
						that.updateMessagesList.push({
							id: message.id,
							reviewStatus: updatereviewStatus
						});
					}
				}
				that.updateChannelLogsList(that.currentSessions);
			},
			changeCurrentSession(session, index) {
				const that = this;
				if(that.updateMessagesList.length > 0) {
					that.starFlagMultiMessage();
				}
				that.updateCurrentSession(session, index);
			},
			starFlagMultiMessage() {
				const that = this;
				_.forEach(that.updateMessagesList, message => {
					const data = {
						projectId: that.currentAIAccount.id,
						message
					};
					that.updateMessageStatus(data);
				});
			},
			updateCurrentSession(session, index, refrence = "switch") {
				const that = this;
				that.sessionChanged = true;
				if(refrence == "switch") {
					that.sessionLoading = true;
					that.currentSessions = {};
				}
				_.map(session.messages, message => {
					message.showDetail = that.showAllIntents;
					if(message.custom_info !== undefined) {
						try {
							message.custom_info_parsed = JSON.parse(message.custom_info);
						} catch(e) {
							message.custom_info_parsed = {};
						}
					} else {
						message.custom_info_parsed = {};
					}
					if(message.analysis && message.analysis.length > 0) {
						message.custom_infoA = JSON.parse(message.analysis[0].custom_info);
						message.mainParameter = message.analysis[0].mainParameter;
						message.confidenceScore = _.pluck(message.analysis, "rating")[0].toFixed(2);
						const {parametersParsed} = message.custom_infoA;
						if(parametersParsed !== undefined) {
							message.parametersParsed = _.values(parametersParsed);
							message.showParameters = true;
						} else {
							message.showParameters = false;
						}
						message.analysis = that.jsonParser(message.analysis);
						_.map(message.analysis, analys => {
							analys.custom_infoA = that.jsonParser(analys.custom_info);
							if(analys.msgtype == "text" || analys.msgtype == "SCAN" || analys.msgtype == "subscribe") {
								analys.contentArr = [];
								const _isFULFILLMENTMSG = analys.content.includes("{FULFILLMENTMSGDELIMITER}");
								if(_isFULFILLMENTMSG == true) {
									const _FULFILLMENTMSGARRAY = analys.content.split("{FULFILLMENTMSGDELIMITER}");
									analys.contentArr = _FULFILLMENTMSGARRAY;
								} else {
									analys.contentArr[0] = analys.content;
								}
							} else if(analys.msgtype == "card") {
								const mediaMsgArr = [];
								const parsed_content = JSON.parse(analys.content);
								for(const key in parsed_content) {
									_.forEach(parsed_content[key], mediaMsg => {
										mediaMsgArr.push(mediaMsg);
									});
								}
								analys.mediaMsgArr = mediaMsgArr;
							} else if(analys.msgtype == "image") {
								analys.parsed_content = JSON.parse(analys.content);
							} else if(analys.msgtype == "quick") {
								analys.parsed_content = JSON.parse(analys.content);
								const quickReplyArr = [];
								const parsed_content = JSON.parse(analys.content);
								for(const key in parsed_content) {
									_.forEach(parsed_content[key], reply => {
										quickReplyArr.push(reply);
									});
								}
								analys.quickReplyArr = quickReplyArr;
							} else if(analys.msgtype == "multi") {
								return that.parseMultiMessages(analys);
							}
							return analys;
						});
					}
					if(!message.reviewStatus) {
						message.reviewStatus = "unflagged";
					}
					return message;
				});
				if(!_.has(session, 'reviewStatus')) {
					session.reviewStatus = 'unchecked'
				}
				if(refrence == "switch") {
					that.currentSessions = {...session
					};
				} else {
					that.currentSessions = session;
				}
				setTimeout(() => {
					that.sessionLoading = false;
				}, 1000);
				that.sessionIndex = index;
			},
			parseMultiMessages(analys) {
				const that = this;
				analys.parsed_content = that.jsonParser(analys.content);
				if(Array.isArray(analys.parsed_content)) {
					_.forEach(analys.parsed_content, (multiMessage, mindex) => {
						if(multiMessage.msgtype == "text") {
							multiMessage.contentArr = [];
							if(multiMessage.content !== undefined) {
								const _isFULFILLMENTMSG = multiMessage.content.includes("{FULFILLMENTMSGDELIMITER}");
								if(_isFULFILLMENTMSG == true) {
									const _FULFILLMENTMSGARRAY = multiMessage.content.split("{FULFILLMENTMSGDELIMITER}");
									multiMessage.contentArr = _FULFILLMENTMSGARRAY;
								} else {
									multiMessage.contentArr[0] = multiMessage.content;
								}
							}
							return multiMessage;
						}
						if(multiMessage.msgtype == "card") {
							multiMessage.mediaMsgArr = that.jsonParser(multiMessage.content);
							return multiMessage;
						}
						if(multiMessage.msgtype == "image") {
							multiMessage.parsed_content = that.jsonParser(multiMessage.content);
							return multiMessage;
						} else if(multiMessage.msgtype == "quick") {
							multiMessage.parsed_content = that.jsonParser(multiMessage.content);
							return multiMessage;
						}
						return multiMessage;
					});
				} else {
					console.log("not array  ", analys.content);
				}
				return analys;
			},
			showMessageDetail(message) {
				const that = this;
				if(message.showDetail == true) {
					message.showDetail = false;
				} else {
					message.showDetail = true;
				}
				const updatedList = _.map(that.currentSessions.messages, cmessage => {
					if(cmessage.id == message.id) {
						return message;
					}
					return cmessage;
				});
				that.currentSessions.messages = updatedList;
			},
			jsonParser(obj) {
				try {
					return JSON.parse(obj);
				} catch(err) {
					return JSON.parse(JSON.stringify(obj));
				}
			},
			onDateUpdate(date) {
				const that = this;
				if(that.isValid(date.start) && that.isValid(date.end)) {
					that.pageNr = 1;
					that.pageSize = 10;
					const data = {
						aiAccount: that.currentAIAccount.id,
						startdate: date.start,
						enddate: date.end,
						pageNr: that.pageNr,
						pageSize: that.pageSize,
						firstCall: false // add a static parameter to show active message on frontend
					};
					that.updateChannelLogs(data);
				} else {
					console.log("invalid date");
				}
			},
			onAccountUpdate(account) {
				const that = this;
				that.pageNr = 1;
				that.pageSize = 10;
				const data = {
					aiAccount: account.id,
					startdate: that.getFilterDate.start,
					enddate: that.getFilterDate.end,
					pageNr: that.pageNr,
					pageSize: that.pageSize,
					firstCall: false // add a static parameter to show active message on frontend
				};
				that.updateChannelLogs(data);
			},
      reload(){
				const that = this;
				that.pageNr = 1;
				that.pageSize = 10;
				const data = {
					aiAccount: that.currentAIAccount.id,
					startdate: that.getFilterDate.start,
					enddate: that.getFilterDate.end,
					pageNr: that.pageNr,
					pageSize: that.pageSize,
					firstCall: false
				};
				that.updateChannelLogs(data);
      },
			reveiwSession(session) {
        let that = this
				let updated_status = session.reviewStatus === "unchecked" ? 'checked' : 'unchecked'
        let data = {
					aiAccount: that.currentAIAccount.id,
          session: {
            id: session.id,
            reviewStatus: updated_status
          }
        }
        that.updateAISession(data).then((resp) => {
          session.reviewStatus = updated_status
          that.updateChannelLogsList(session);
        })
			},
			isValid(value) {
				if(value === undefined || value === null || (typeof value === "object" && Object.keys(value).length === 0) || (typeof value === "string" && value.trim().length === 0)) {
					return false;
				}
				return true;
			},
      downloadChatLog(){
				const that = this;
        that.showInnerDownloadLoading = true
				const data = {
					aiAccount: that.currentAIAccount.id,
					startdate: that.getFilterDate.start,
					enddate: that.getFilterDate.end,
				}
        that.requestAiMessageLog(data).then((resp) =>{
        let fileName = `EVO_Dynamics_report_${this.currentAIAccount.name}_${this.getFilterDate.start}_to_${this.getFilterDate.end}_message_history`
        var fileData = _.map(resp.filtered_data , (record) => {
          let chatInfo = {}
          _.forEach((resp.headers) , (title , index) => {
            chatInfo[title] = record[index]
          })
            return chatInfo
        });
        var ws = XLSX.utils.json_to_sheet(fileData);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "People");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        }).catch((err) =>{
          console.log("downloadChatLog error " , err)
        }).finally(() => {
          that.showInnerDownloadLoading = false
        })
      }
	},
	watch: {
		currentSessions(newVal) {
				const that = this;
				const AISessions = _.map(that.AISessions, session => {
					if(session !== undefined) {
						if(session.messages.length > 0) {
							session.starred = _.some(session.messages, message => message.starred);
							session.flagged = _.some(session.messages, message => message.reviewStatus == "flagged");
							const sessionKeys = Object.keys(session.messages[0]);
							const hasUsername = _.contains(sessionKeys, "nick");
							if(hasUsername) {
								session.userName = session.messages[0].nick;
							}
							session.hasUsername = hasUsername;
							if(session.id == newVal.id) {
								session.class = "activeChat";
								return session;
							}
							session.class = "";
							return session;
						}
						session.starred = false;
						session.flagged = false;
						session.hasUsername = false;
						return session;
					}
					console.log("undefined session ==> ", session);
				});
				const unique = _.uniq(AISessions, "id");
				this.$nextTick(() => {
					this.$refs.chatBox.scrollTop = 0;
				});
				that.AISessions = unique;
			},
			showAllIntents(newVal) {
				const that = this;
				_.forEach(that.currentSessions.messages, message => {
					message.showDetail = newVal;
				});
			}
	},
	created() {
		eventBus.$on("updateDate", this.onDateUpdate);
		eventBus.$on("updateAIAccount", this.onAccountUpdate);
	},
	beforeDestroy() {
		const that = this;
		if(that.updateMessagesList.length > 0) {
			that.starFlagMultiMessage();
		}
		eventBus.$off("updateDate", this.onDateUpdate);
		eventBus.$off("updateAIAccount", this.onAccountUpdate);
	},
	destroyed() {
		eventBus.$off("updateDate", this.onDateUpdate);
		eventBus.$off("updateAIAccount", this.onAccountUpdate);
	},
	mounted() {
		const that = this;
		const data = {
			aiAccount: that.currentAIAccount.id,
			startdate: that.getFilterDate.start,
			enddate: that.getFilterDate.end,
			pageNr: that.pageNr,
			pageSize: that.pageSize,
			firstCall: true // add a static parameter to show active message on frontend
		};
		that.updateChannelLogs(data);
	}
};
